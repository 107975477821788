.bg-dark-gray {
  background: $dark-gray-sidebar;
}

.form-readonly {
  padding: 0.125rem 0;
  color: #616b74;
}

.form-control {
  &.has-error {
    border-color: #f86c6b;
  }
}

.rdt_Table {
  .rdt_TableCol,
  .rdt_TableCell {
    font-size: 14px;
  }
}

ol.breadcrumb {
  margin-bottom: 0;
}

.modal-xxl {
  max-width: 94%;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    position: absolute;
    top: 0;
    left: 50px;
    margin-left: 0;
  }
}

.d-flex {
  &-col,
  &-row {
    display: flex;
  }
  &-col {
    flex-direction: column;
  }
  &-row {
    flex-direction: row;
  }

  .space-between {
    justify-content: space-between;
  }
}
.flex-1 {
  flex: 1;
}

.p-relative,
.p-rel {
  position: relative;
}

.p-absolute,
.p-abs {
  position: absolute;

  &.p-abs-full {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.o-scroll {
  overflow: scroll;
}

.o-y-scroll {
  overflow-y: scroll;
}

.o-x-scroll {
  overflow-x: scroll;
}

.input-line-height {
  height: 2.25rem;
  line-height: 2.25rem;
}

.button-cell {
  display: inline-block;
  height: 35px;
  line-height: 35px;
}

.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast--dark {
}
.Toastify__toast--default {
}
.Toastify__toast--info {
  background: $blue;
}
.Toastify__toast--success {
}
.Toastify__toast--warning {
}
.Toastify__toast--error {
}
.Toastify__toast-body {
}
